.toggle-button-group {
  display: flex;
  background-color: var(--theme-variant-color);
}

.toggle-button {
  background-color: var(--theme-variant-color);
  cursor: pointer;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-top: 0 !important;
  width: max-content !important;
}

.toggle-button:hover {
  background-color: #f0f0f0;
  color: #667085;
}

.toggle-button.selected {
  background-color: #fff;
  color: #344054;
}
