.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.togglerSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(229,231,235);
  -webkit-transition: .4s;
  transition: .4s;
}

.togglerSlider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  margin: 2px 0;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.075);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .togglerSlider:before {
  left: 22px;
}

input:checked + .togglerSlider {
  background-color: #11B078FF;
}

input:focus + .togglerSlider {
  box-shadow: 0 0 1px #11B078FF;
}


/* Rounded togglerSliders */
.togglerSlider.round {
  border-radius: 34px;
}

.togglerSlider.round:before {
  border-radius: 50%;
}
