
#root{
    --theme-surface-color: white;
    --theme-border-color:#efefef;
    --theme-bg-container:white;
}

.dropdown-menu{
    padding: 12px;
}


.force-white{
    background: white !important;
}

.force-card{
    background: rgba(0,0,0,.03)!important;
}

#topBar-wrapper .noHover .dropdown-menu .item:hover{
    background: none;
}

.topbar-icon{
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-right: 10px;
    background: rgba(0,0,0,.03)!important;
}

.active .topbar-icon{
    background: var(--theme-primary-container)!important;
}

#topBar-wrapper .dropdown-menu .item:hover .topbar-icon{
    background:var(--theme-primary-container) !important;
}
.topBar-bottom{
    font-size: 10px;
    margin: 0;
    color: var(--theme-on-secondary-container);
}
.topBar-top{
    font-size: 14px;
    margin: 0;
}
.topBarTheme-main-wrapper{
    flex: 1;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

#root{
    --theme-surface-color: white;
    --theme-border-color:#eaeaea;
    --theme-bg-container:white;
}

.dropdown-menu{
    padding: 12px;
}

.retro-card{
    background: #fff !important;
}

.force-white{
    background: white !important;
}

.force-card{
    background: rgba(0,0,0,.03)!important;
}

#topBar-wrapper .noHover .dropdown-menu .item:hover{
    background: none;
}

.topbar-icon{
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-right: 10px;
    background: rgba(0,0,0,.03)!important;
}

.active .topbar-icon{
    background: var(--theme-primary-container)!important;
}

#topBar-wrapper .dropdown-menu .item:hover .topbar-icon{
    background:var(--theme-primary-container) !important;
}
.topBar-bottom{
    font-size: 10px;
    margin: 0;
    color: var(--theme-on-secondary-container);
}
.topBar-top{
    font-size: 14px;
    margin: 0;
}
.topBarTheme-main-wrapper{
    flex: 1;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.search-box{
    border: 1px solid #dedede !important;
}

#wrapper{
    padding-bottom: 100px !important;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
