.switch-tabs{
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    justify-content: space-between;
    /* padding: 5px; */
    /* gap: 10px; */
    /* padding-top: 0.5rem; */
    /* padding-top: 2px; */
    margin-bottom: 1rem;
    z-index: 5;
    /* border:  1px solid black; */
    border-radius: 60px;
    font-size: 0.9rem;
    background-color: #E6E6E6;
}

.switch-tabs .switch-tab-option{
    white-space: nowrap;
    /* padding: 0.1rem; */
    /* margin-right: 1rem; */
    transition: ease-in-out all 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #8B8B8B;
    padding: 7px 8px;
    font-weight: 400;
    /* border-bottom: 2px solid var(--theme-bg-container); */
}

.switch-tabs .switch-tab-option-active{
    white-space: nowrap;
    /* padding: 0.75rem; */
    /* margin-right: 1rem; */
    transition: ease-in-out all 0.15s;
    cursor: pointer;
    /* color: var(--theme-text-color); */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 7px 8px;
    border-radius: 60px;
    background-color: #1A1A1A;
}

/* .switch-tabs .switch-tab-option:hover{
    color: var(--theme-text-color);
    background: var(--theme-primary-container);
} */
