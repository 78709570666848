.rnc {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
}

.rnc-row {
    display: flex;
    align-items: stretch;
}

.rnc-column {
    display: flex;
    margin-left: 8px;
    flex-direction: column;
    justify-content: space-between;
}

.rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
}

.rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
}

.rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.rnc-input {
    border: none;
    margin-left: 50px;
    width: 100%;
    padding: 10px 6px;
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
}
