input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.inline-input label{
    margin-bottom: .75rem;
}

::placeholder {
    color: var(--theme-on-variant-color, #333);
}

input[type="text"], input[type="number"], input[type="password"], input[type="email"], input[type="date"], input[type="time"] {
    font-size: 1rem;
    padding: 1rem;
    border-radius: 0.2rem;
    color: var(--theme-text-color, #333);
    background-color: var(--theme-variant-color, #f7f7fa);
    border: 1px solid var(--theme-outline-color, #f2f2f2);
    width: 100%;
    outline: none;
    transition: ease all 0.1s;
}

/* Remove arrows to change numbers in input[type="number"] */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

input[type="date"]{
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
position: absolute;
top: 0;
right: 0;
width: 100%;
height: 100%;
padding: 0;
color: transparent;
background: transparent;
}

input[type="radio"]{
    margin-bottom: 0.2rem;
    accent-color: #333;
}

input:focus {
    border-color: var(--theme-primary-color);
    background-color: var(--theme-surface-color) !important;
}

.morph-label {
    padding: 0 0.25rem;
    position: absolute;
    top: 1rem;
    left: 0.75rem;
    color: var(--theme-on-variant-color, var(--theme-muted-color));
    transition: ease-out all 0.15s;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
}

.morph-active {
    width: auto;
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff calc(50% + 2px), transparent  calc(50% - 2px), transparent 100%) !important;;
}

input:focus + .morph-label {
    width: auto;
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    background-color: var(--theme-surface-color) !important;
    color: var(--theme-primary-color) !important;
}

input:-webkit-autofill + .morph-label {
    width: auto;
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    border-radius: 0.2rem;
    background-color: var(--theme-surface-color) !important;
    /* background: linear-gradient(to bottom, #ffffff 0%, #ffffff 60%, transparent 60%, transparent 100%) !important; */
    color: var(--theme-primary-color) !important;
}

.text-input-label {
    font-size: 0.8rem;
    color: var(--theme-on-variant-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.text-input-label.valid {
    color: var(--theme-primary-color) !important;
}

.valid {
    border-color: var(--theme-primary-color) !important;
}


.text-input-label.invalid {
    color: var(--theme-error-color) !important;
}

.invalid {
    border-color: var(--theme-error-color) !important;
}

.search-box {
    width: 100%;
    padding-right: 2.5rem !important;
    outline: none;
    transition: ease all 0.3s;
    background-color: rgba(0, 0, 0, 0.03) !important;
    border: 1px solid var(--theme-border-color) !important;
}

select:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--theme-surface-color);
    border-color: var(--theme-primary-color);
}

select {
    -webkit-appearance: none;
    appearance: none;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 0.2rem;
    color: var(--theme-text-color, #333);
    border: 1px solid var(--theme-outline-color, #f2f2f2);
    background-color: var(--theme-surface-color);
    width: 100%;
    outline: none;
    transition: ease all 0.1s;
}

.select-wrapper {
    position: relative;
}

.select-wrapper i {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

input.valid {
    background: var(--theme-surface-color);
}

input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    accent-color: var(--theme-primary-color);
}

textarea {
    padding: 0.75rem;
    border-radius: var(--size-xsmall);
    border: 1px solid var(--theme-border-color);
    width: 100%;
    font-family: var(--font-family);
    outline: none;
    transition: ease all 0.1s;
}

label{
    font-size: .8rem !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    text-transform: uppercase !important;
    color: var(--theme-secondary-color) !important;
    font-family: var(--font-family) !important;
}

div [data-testid="read-view-input-heading"]{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 10px 0;
    margin-bottom: -6.5px;
}

div [data-testid="input-heading"]{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 0;
}

div [data-testid="read-view-input-text"]{
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 0;
    margin-bottom: -4px;
}

div [data-testid="input-text"]{
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 0;
}

.spinner > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: var(--theme-surface-color);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.btn-primary .spinner > div{
    background-color: var(--theme-on-primary-color) !important;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}


@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.mini-loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid var(--theme-primary-color);
    width: 34px;
    height: 34px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.file-input {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 0.75rem;
    font-size: 1rem;
    background-color: var(--theme-primary-container);
    cursor: pointer;
    border: 1px dashed #000;
    margin: 0.25rem;
    transition: ease all 0.3s;
}

.file-input:hover {
    background-color: #111;
    color: #fff !important;
    transform: rotate(90deg);
    border-radius: 5rem;
}

.delta-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 100%;
    border-radius: 0 0.3rem 0.3rem 0;
    background-color: var(--theme-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: ease all 0.2s;
}

.delta-btn:hover {
    background-color: #000;
}

.inc-dec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inc-dec .value{
    border: none;
    padding: 0px;
    background: transparent;
    text-align: center;
    font-size: 0.8rem;
}

.inc-dec .plusMinus{
    min-width: 25px;
    min-height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #111;
    cursor: pointer;
    font-weight: bold;
}

.inc-dec .plusMinus:hover{
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
    color: #fff;
}

.no-border-input{
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

