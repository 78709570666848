
body{
    --font-family : -apple-system,'proxima-nova',"Segoe UI",'Roboto', sans-serif;
    --shadow-rg:rgba(0, 0, 0, 0.1) 0px 1px 4px;
    --shadow-md:rgba(0, 0, 0, 0.1) 0px 1px 4px;
    --shadow-lg:rgba(0, 0, 0, 0.1) 0px 1px 4px;
}

@media (max-width: 576px){
    html{
        font-size: 14px !important;
    }
}

#wrapper{
    position: relative !important;
    padding: 1rem 4rem 3rem 4rem !important;
    padding-bottom: 100px !important;
}

@media (max-width: 768px) {
    #wrapper{
        width: 100% !important;
        padding: 1rem 1rem !important;
        flex-shrink: 0 !important;
    }
}

#sidebar-top .icon{
    padding: .5rem .7rem;
    padding-bottom: .4rem;
    border: 1px solid var(--theme-border-color);
    border-radius: var(--size-large);
    cursor: pointer;
    transition: ease all 0.2s;
}

#sidebar-top .icon svg{
    margin-top: 2px;
}

#sidebar-top .icon:hover{
    background: var(--theme-link-container);
    border: 1px solid var(--theme-link-container);
}

.btn.btn-secondary{
    color: var(--theme-text-color) !important;
    border: 1px solid var(--theme-text-color) !important;
}

.btn.btn-secondary:hover{
    color: var(--theme-surface-color) !important;
    background-color: var(--theme-primary-color) !important;
    border-color: var(--theme-primary-color) !important;
}

.btn{
    border-radius: 0.4rem !important;
}

.scale-background{
    border-radius: 0.75rem;
    cursor: pointer;
    position: relative;
    transition: ease-in-out all 0.3s;
    background-size: 100% !important;
}

.scale-background:hover{
    transform: scale(104%);
    background-size: 110% !important;
}

.card-border{
    border-radius: 0.5rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: ease all 0.2s;
    padding: var(--size-medium);
    background-color: var(--theme-surface-color);
    box-shadow: var(--shadow-sm);
    width: 100%;
}

.card-border:hover{
    box-shadow: 0rem 0.2rem 0.6rem rgba(0,0,0,0.2);
    border-color: #fff;
}

input[type="text"], input[type="number"], input[type="password"], input[type="email"], input[type="date"], input[type="time"] {
    padding: 1rem ;
}

select {
    padding: 1rem ;
}

