.multi-select {
  -webkit-appearance: none;
  appearance: none;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.2rem;
  color: var(--theme-text-color, #333);
  border: 1px solid var(--theme-outline-color, #f2f2f2);
  background-color: var(--theme-surface-color);
  width: 100%;
  outline: none;
  transition: ease all 0.1s;
}

.multi-select:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--theme-surface-color);
  border-color: var(--theme-primary-color);
}
